import React from "react";
import "./TeamMem.css";

function TeamMember(props) {
    return (
        <div className="m-3">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={props.imageLink}
                    alt=""
                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a
                          target="_blank"
                          href={props.email}
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href={props.linkedin}
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>{props.name}</h3>
                  <span>{props.title}</span>
                </div>
              </div>
            </div>
    )
}

export default TeamMember;