import React from "react";
import { SiGmail } from "react-icons/si";
import "./TeamMem.css";
import TeamMember from "./TeamMember";

export default function TeamMembers() {
  return (
    <div>
      <>
        <div className="container flex justify-center">
          <div style={{ justifyContent: "center" }} className="flex flex-wrap max-w-screen-lg">
            <TeamMember imageLink={require("../../images/Douglas_Headshot_Square.jpeg")}
                        email={"mailto:dzhu2003@student.ubc.ca"}
                        linkedin={"https://ca.linkedin.com/in/douglas-zhu-518a3a268"}
                        name={"Douglas"}
                        title={"Captain"}
            />
            <TeamMember imageLink={require("../../images/Heidi_Headshot_Square.jpg")}
                        email={"mailto:hsnow@student.ubc.ca"}
                        linkedin={"https://www.linkedin.com/in/heidi-snow/"}
                        name={"Heidi"}
                        title={"Chem Lead"}
            />
            <TeamMember imageLink={require("../../images/Ibaad_Headshot_Square.jpg")}
                        email={"mailto:iabro@student.ubc.ca"}
                        linkedin={"https://www.linkedin.com/in/ibaadabro/"}
                        name={"Ibaad"}
                        title={"Mech Lead"}
            />
            <TeamMember imageLink={require("../../images/Cora_Headshot_Square.jpg")}
                        email={"mailto:corashao@student.ubc.ca"}
                        linkedin={"https://www.linkedin.com/in/cora-shao-ece/"}
                        name={"Cora"}
                        title={"Elec Lead"}
            />
            <TeamMember imageLink={require("../../images/Harshit_Headshot_Square.jpg")}
                        email={"mailto:hsethi02@student.ubc.ca"}
                        linkedin={"https://www.linkedin.com/in/harshit-sethi-0b9b2422a/"}
                        name={"Harshit"}
                        title={"Operations Lead"}
            />
          </div>
        </div>
      </>
    </div>
  );
}
