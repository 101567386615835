import React from "react";
import iac from "../../images/iac.jpg";
import pastCompetion2 from "../../images/pastCompetion2.png";
import pastCompetion3 from "../../images/pastCompetion3.jpg";
import Team from "../../images/MicrosoftTeams-image (1).png";
import IMAGES from "../../images/images.js";
import TeamMembers from "./TeamMembers.js";
import quotes from "../../images/quotes.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.css";
import Banner from "./FAQ/Banner.js";
import Contacts from "../Extras/Contacts.js";
const About = () => {
  AOS.init({
    disable: false,
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    easing: "ease",
    once: false, // whether animation should happen only once - while scrolling down
    mirror: true,
    anchorPlacement: "top-bottom",
  });
  const images = [
    {
      img: IMAGES.sample,
    },
    {
      img: IMAGES.sample,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <img
          src={Team}
          width={"100%"}
          
          style={{ objectFit: "cover", maxHeight: 700 }}
        />
      </div>

      <div className="w-full flex justify-center">
        <div className="w-9/12">
          <div className="about-flexbox">
          <h3
            className="mt-16 text-center font-medium w-full"
            style={{ color: "#a83939", fontWeight: 700 }}
          >
            Our Mission
          </h3>
            <div
              style={{
                borderLeft: "4px solid #A83939",
                fontSize: 24,
                fontWeight: 500,
              }}
              className="px-2 "
            >
              To innovate at the frontier of undergraduate engineering and
              design solutions for living on Mars.{" "}
              <span>
                {" "}
                <img
                  data-aos-offset="20"
                  data-aos-delay="80"
                  data-aos="slide-left"
                  className="img-fluid py-1 float-right"
                  src={quotes}
                  alt=""
                />
              </span>
            </div>
          </div>
            <a style={{ color: "#a83939" }} target="_blank" rel="noreferrer">
              {" "}
              <h3
                className="mt-16 text-center font-medium w-full"
                style={{ color: "#a83939", fontWeight: 700 }}
              >
                Past Competitons
              </h3>
              <div className="row mt-4">
                <div className="col-lg-4 justify-center">
                  <div className="auction-card style10 justify-center">
                    <div className="auction-img">
                      <img style={{minHeight : 265}} className="w-100" src={iac} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 justify-center">
                  <div className="auction-card style10 justify-center">
                    <div className="auction-img">
                      <img style={{minHeight : 265, objectFit : "cover"
                      }} className="w-100" src={pastCompetion2} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 justify-center">
                  <div className="auction-card style10 justify-center">
                    <div className="auction-img">
                      <img style={{minHeight : 265, objectFit : "cover"}} className="w-100" src={pastCompetion3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          <div id="skill">
            <div className="skill-main">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center wow fadeIn">
                      <h3
                        className="text-center font-medium w-full"
                        style={{ color: "#a83939", fontWeight: 700, }}
                      >
                        Values
                      </h3>

                      <div
                        style={{
                          borderLeft: "4px solid #A83939",
                          fontSize: 16,
                          fontWeight: "normal",
                        }}
                        className="px-2 underline1 text-left w-full"
                      >
                        <p style={{ color: "black" }}>
                        
                          At UBC Mars Colony, safety is the most important value to
                          uphold. All our members are properly trained and
                          contribute to maintaining a physically and mentally
                          safe work environment. We make sure that all our tasks
                          and projects are conducted with safest procedures
                          possible while making significant progress.
                        </p>
                        <p style={{ color: "black" }}>
                          We promote technical and non-technical experiential
                          learning. During their time at UBC Mars Colony, our
                          members utilize problem-solving, teamwork,
                          flexibility, critical thinking, and other relevant
                          skills to make significant progress and ensure that
                          our projects a success.
                        </p>{" "}
                        <p style={{ color: "black" }}>
                          We strive to have great communication to collaborate
                          as a team. For our projects to be completed on time
                          and for our members to learn during the process,
                          collaboration is key to deliver the best possible
                          results and keep the team moving forward.
                        </p>{" "}
                        <p style={{ color: "black" }}>
                          We facilitate Mars colonization and add value to the
                          community through our activities. We participate in
                          competitions such as the International Astronautical
                          Congress (IAC) to present our paper on our main
                          project and reach out to schools to get students
                          interested in Mars colonization and the space
                          industry.
                        </p>{" "}
                        <p style={{ color: "black" }}>
                          We are open to all regardless of age (actual or
                          perceived), ancestry, colour, family status, marital
                          status, physical or mental disability, place of
                          origin, political belief, race, religion, sex, sexual
                          orientation, gender identity or expression, or
                          criminal conviction unrelated to employment.
                        </p>{" "}
                        <span>
                          {" "}
                          <img
                            data-aos-offset="20"
                            data-aos-delay="80"
                            data-aos="slide-left"
                            className="img-fluid py-1 float-right"
                            src={quotes}
                            alt=""
                          />
                        </span>
                      </div>

                      {/* <div className="underline1"></div>
                      <div className="underline2"></div> */}
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  {/* <div className="col-md-6">
                  <div className="skill-details text-left">
                    
                  </div>
                </div> */}
                  {/* <div className="col-md-6"> */}
                  <div className="skill-details">
                    <div className="content">
                      <div className="col"></div>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    <h3
                      className="mt-16 text-center font-medium w-full pb-4"
                      style={{ color: "#a83939", fontWeight: 700 }}
                    >
                      Team Leads
                    </h3>
                    <TeamMembers />
                  </div>
                  {/* <Banner/> */}
                  <div>
                    <h3
                      className="mt-16 text-center font-medium w-full"
                      style={{ color: "#a83939", fontWeight: 700 }}
                    >
                      {"FAQ"}
                    </h3>
                    <Banner />
                    {/* Skills
                
                     <AllSkills /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us">
        <Contacts bgcolor={"red-footer"}/>
      </div>
    </>
  );
};

export default About;
